<template>
	<layoutWide>
		<sub-navbar page="Transaction" />
		<div>Payments To Suppliers</div>
	</layoutWide>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
export default {
	name: 'PaymentsToSuppliers',
	components: {
		SubNavbar,
	},
	setup() {
		return {
			//
		}
	},
}
</script>

<style lang="scss" scoped></style>
